import React from 'react';
import { StaticImage } from "gatsby-plugin-image";

function NewsSimple() {
    return (
        <div className="portfolio-related-work ptb--120" id="news">
            <div className="about-wrapper p-4 p-md-0">
                <div className="container">
                    <div className="row row--35">
                        <div className="col-lg-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <span className="primary-subtitle">Announcements</span>
                                    <h2 className="title">Current News From the Underground</h2>
                                </div>
                                <div className="row mt--30">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="single-tab-content">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">
                                                            <p
                                                                className="text-primary text-uppercase mb--0 fontWeight400"
                                                                style={{"fontSize": "0.875rem"}}
                                                            >
                                                                Valvazor Square, Sunday, 1 December, 16:30
                                                            </p>
                                                            <h3>LIGHTING OF LIGHTS, St Nicholas Fair</h3>
                                                            <p className="mb-15px">
                                                                The magic of the lights, the positive atmosphere of the
                                                                city's lively events, St Nicholas' treats on the lined
                                                                stalls in Valvazor Square, the playfulness and joy of
                                                                the New Year.
                                                            </p>
                                                            <p className="mb-15px font-weight-bold">
                                                                The lights will be switched on by the Perkakšns
                                                                percussion band, and you can watch the fire show "School
                                                                for Dragons".
                                                            </p>
                                                        </div>

                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/news/prizig-luck-2.jpg"
                                                                    alt="Nočni pohod z baklami"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="single-tab-content">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">
                                                            <p
                                                                className="text-primary text-uppercase mb--0 fontWeight400"
                                                                style={{"fontSize": "0.875rem"}}
                                                            >
                                                                Valvazor Square / Sitarjevec Mine Litija, Sunday, 1
                                                                December, 18:00
                                                            </p>
                                                            <h3>NIGHT WALK WITH TORCHES TO SITARJEVEC MINE</h3>
                                                            <p className="mb-15px font-weight-bold">
                                                                At 18:00, we will set off from the Old Town with torches
                                                                in hand to the Sitarjevec Mine in Litija. On this day,
                                                                the visit to the mine's Export Tunnel will be free of
                                                                charge.
                                                            </p>
                                                            <p className="mb-15px">
                                                                Before the hike, hikers will pick up their entry number
                                                                at the TIC Litija stand, pick up their torches and light
                                                                them. Participants take part in the hike at their own
                                                                risk.
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/news/pohod.jpg"
                                                                    alt="Night Walk with Torches"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="single-tab-content">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">
                                                            <p
                                                                className="text-primary text-uppercase mb--0 fontWeight400"
                                                                style={{"fontSize": "0.875rem"}}
                                                            >
                                                                Valvazor Square, Friday, 20 December, 16:30–20:00
                                                            </p>
                                                            <h3>CHRISTMAS FAIR</h3>
                                                            <p className="mb-15px">
                                                                Valvazor Square will be particularly magical on <strong>Friday
                                                                afternoon, 20 December, from 16:30 to 20:00 </strong> .
                                                                The Christmas Market will add to the festive atmosphere
                                                                in the town. Vendors at the stalls will make
                                                                sure that the variety of products on offer will attract
                                                                every visitor, who will be able to choose
                                                                between festive culinary delights and unique products
                                                                suitable for gift-giving. The magic of Lithia will
                                                                be enhanced by the fair's cultural programme.
                                                            </p>
                                                            <ul className="list-group list-group-flush mt-3">
                                                                <li
                                                                    className="list-group-item d-flex align-items-center border-bottom-0"
                                                                    style={{
                                                                        background: "#f9f9f9",
                                                                        borderTopLeftRadius: "7px",
                                                                        borderTopRightRadius: "7px",
                                                                        marginBottom: "0",
                                                                    }}
                                                                >
                                                                    <span
                                                                        className="badge bg-primary me-3">16:30–17:15</span>
                                                                    <span className="ml-2">Pupils of the Litija Music School, MePZ KUD Jevnica</span>
                                                                </li>
                                                                <li
                                                                    className="list-group-item d-flex align-items-center border-bottom-0"
                                                                    style={{
                                                                        background: "#f9f9f9",
                                                                        marginBottom: "0",
                                                                    }}
                                                                >
                                                                    <span className="badge bg-primary me-3">17:30</span>
                                                                    <span className="ml-2">PIKA PIŠE, performance with the arrival of Santa Claus (Simple Friends)</span>
                                                                </li>
                                                                <li
                                                                    className="list-group-item d-flex  align-items-center border-bottom-0"
                                                                    style={{
                                                                        background: "#f9f9f9",
                                                                        borderBottomLeftRadius: "7px",
                                                                        borderBottomRightRadius: "7px",
                                                                        marginBottom: "0",
                                                                    }}
                                                                >
                                                                    <span className="badge bg-primary me-3">18:30</span>
                                                                    <span className="ml-2">Concert of the Litija Wind Orchestra</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/news/sejem.jpg"
                                                                    alt="Christmas Fair"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="single-tab-content">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">
                                                            <p
                                                                className="text-primary text-uppercase mb--0 fontWeight400"
                                                                style={{"fontSize": "0.875rem"}}
                                                            >
                                                                Market in front of the Municipality of Litija, Saturday,
                                                                21 December, 8:00–12:00
                                                            </p>
                                                            <h3>CHRISTMAS FAIR</h3>
                                                            <p className="mb-15px">
                                                                On Saturday, 21 December, morning, from 8:00 to 12:00,
                                                                it will be magical at the market in front of the
                                                                Municipality of Litija. Vendors at their stalls will
                                                                make sure that the variety of products on offer will
                                                                attract every visitor, who will be able to choose
                                                                between festive culinary delights and unique products
                                                                suitable for gift-giving. The magic of Lithia will be
                                                                enhanced by the fair's cultural programme.
                                                            </p>
                                                            <ul className="list-group list-group-flush mt-3">
                                                                <li
                                                                    className="list-group-item d-flex align-items-center border-bottom-0"
                                                                    style={{
                                                                        background: "#f9f9f9",
                                                                        borderRadius: "7px",
                                                                        marginBottom: "0",
                                                                    }}
                                                                >
                                                                    <span className="badge bg-primary me-3">9:30 and 10:30</span>
                                                                    <span className="ml-2">Pupils of the Bučar Music School</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/news/bozicni-sejem-3.jpg"
                                                                    alt="Božični sejem dan 2"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="single-tab-content">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">
                                                            <p
                                                                className="text-primary text-uppercase mb--0 fontWeight400"
                                                                style={{"fontSize": "0.875rem"}}
                                                            >
                                                                Sitarjevec Mine, 26 - 30 December, 16:00 - 19:00
                                                            </p>
                                                            <h3>MINERAL JASLICE AT THE SITARJEVEC LITIJA MINING
                                                                MILL</h3>
                                                            <p className="mb-15px">
                                                                In the week between Christmas and New Year, we invite
                                                                you to visit the Sitarjevec Mine in Litija, one
                                                                of the richest mineral mines in Europe. <strong>During
                                                                these days, visitors will be able to see the mineral
                                                                nativity scene in the Export Tunnel free of charge.
                                                                Shortened tours will take place between 16:00
                                                                and 19:00</strong>. From Christmas to Candlemas, the
                                                                mine will display a unique nativity scene made from
                                                                the rocks and minerals of the Sitarjevec mine. This
                                                                year&#39;s mineral nativity scene will be set up by
                                                                Miha Kos.
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/news/mineralne-jaslice.jpg"
                                                                    alt="Mineral Nativity Scene"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="single-tab-content">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">
                                                            <p
                                                                className="text-primary text-uppercase mb--0 fontWeight400"
                                                                style={{"fontSize": "0.875rem"}}
                                                            >
                                                                Sitarjevec Mine, Saturday, 28 December, 18:00 and 19:00
                                                            </p>
                                                            <h3>CONCERT AT THE SITARJEVEC LITIJA MINE</h3>
                                                            <p className="mb-15px">
                                                                The MVS Lipa will give a concert on Saturday, 28
                                                                December at 18:00 and 19:00 to enliven the free
                                                                crib tours in the mine.
                                                            </p>
                                                        </div>

                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/news/koncert-1.jpg"
                                                                    alt="Koncert one"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="single-tab-content">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">
                                                            <p
                                                                className="text-primary text-uppercase mb--0 fontWeight400"
                                                                style={{"fontSize": "0.875rem"}}
                                                            >
                                                                Sitarjevec Mine, Sunday, 29 December, 18:00 and 19:00
                                                            </p>
                                                            <h3>CONCERT AT THE SITARJEVEC LITIJA MINE</h3>
                                                            <p className="mb-15px">
                                                                On Sunday, 29 December at 18:00 and 19:00, the vocal
                                                                group TRIAD will give a concert to enrich the
                                                                free crib tours in the mine.
                                                            </p>
                                                        </div>

                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/news/koncert-2.jpg"
                                                                    alt="Koncert 2"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="single-tab-content">
                                                    <div className="row">
                                                        <div className="col-md-7 pr-5 pt-4">
                                                            <p
                                                                className="text-primary text-uppercase mb--0 fontWeight400"
                                                                style={{"fontSize": "0.875rem"}}
                                                            >
                                                                Sitarjevec Mine, Thursday 26 December at 17:00
                                                            </p>
                                                            <h3>ANIMATED Nativity scene in front of the SITARJEVEC
                                                                MINE</h3>
                                                            <p className="mb-15px">
                                                                On Thursday, 26 December at 17:00, we invite you to walk
                                                                from Litija or from the packing station at
                                                                the Health Centre Litija to the Sitarjevec Mine Litija
                                                                and <strong>listen to the Christmas story of an animated
                                                                nativity scene in the sand performed by Irena Režek,
                                                                accompanied by ŽVS Lipa Litija and narrator
                                                                Maja Morela.</strong> Before and after the performance
                                                                of the Christmas story, a free tour of the mine's
                                                                Export Tunnel is available.
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-md-5 flex justify-content-start align-items-start mt-3">
                                                            <div className="thumb hover-slide-up">
                                                                <StaticImage
                                                                    src="../../assets/images/news/animirane-jaslice.png"
                                                                    alt="Animirane jaslice"
                                                                    placeholder="blurred"
                                                                    imgClassName="img-fluid w-100"
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsSimple;
